import moment from 'moment';

angular.module('org.saga.service', ['ngResource',
   require('angular-cookies'),
   'http-auth-interceptor',
   'org.saga.values'])

   .filter('capitalize', function() {
      return function(input) {
         return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
      };
   })

   .service('Widget', ['$resource', function($resource) {
      return $resource('api/widget/:name', { name: '@name' }, {});
   }])

   .service('HumanTask', ['$resource', function($resource) {
      return $resource('api/humantask/:id', { id: '@id' }, {
         search: {
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('Team', ['$resource', function($resource) {
      return $resource('api/humantask/of/:username', { username: '@username' }, {
         getUserTasks: {
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('TaskOps', ['$resource', function($resource) {
      return $resource('api/humantask', { username: '@username' }, {
         releaseAll: {
            url: 'api/humantask/release',
            method: 'PUT',
            isArray: true
         },
         urgeAll: {
            url: 'api/humantask/urge',
            method: 'PUT',
            isArray: true
         },
         delegateAll: {
            url: 'api/humantask/delegate/:username',
            method: 'PUT',
            isArray: true
         }
      });

   }])

   .service('Wizard', ['$resource', function($resource) {
      return $resource('api/wizard', {}, {});
   }])

   .service('HumanTaskOperation', ['$resource', function($resource) {
      return $resource('api/humantask/:id/operation/:operation', { id: '@id', operation: '@operation' }, {
         update: {
            method: 'PUT'
         }
      });
   }])

   .service('Identity', ['$resource', function($resource) {
      return $resource('api/identity/:name', { name: '@name', query: '@query' }, {
         search: {
            url: 'api/identity/search/:query',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('IdentityRoles', ['$resource', function($resource) {
      return $resource('api/identity/:name/roles', { name: '@name' }, {
         update: {
            method: 'PUT'
         }
      });
   }])

   .service('SectionEvent', ['$resource', function($resource) {
      return $resource('api/section/event', {}, {
         push: {
            url: 'api/section/event',
            method: 'POST'
         }
      });
   }])

   .service('PriorityTask', ['$resource', function($resource) {
      return $resource('api/teamtask/priority', {}, {
         fetch: {
            url: 'api/teamtask/priority',
            method: 'POST'
         }
      });
   }])

   .service('MailboxConfig', ['$resource', function($resource) {
      return $resource('api/mailbox/config/:id', { id: '@id' }, {});
   }])

   .service('Queues', ['$resource', function($resource) {
      return $resource('api/queues', {}, {
         fetch: {
            url: 'api/queues',
            method: 'POST',
            isArray: true
         },
         tasks: {
            url: 'api/queues/tasks',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('HumanTaskFlow', ['$resource', function($resource) {
      return $resource('api/humantask/flow', { role: '@role' }, {
         fetch: {
            method: 'POST'
         },
         role: {
            url: 'api/humantask/flow/:role',
            method: 'POST'
         }
      });
   }])

   .service('Mailbox', ['$resource', function($resource) {
      return $resource('api/mailbox', {}, {
         fetch: {
            url: 'api/mailbox',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('Person', ['$resource', function($resource) {
      return $resource('api/person/:id', { id: '@id' }, {
         search: {
            url: 'api/person/search',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('Manager', ['$resource', function($resource) {
      return $resource('api/manager/:id', { id: '@id' }, {
         team: {
            url: 'api/manager/team',
            method: 'GET',
            isArray: true
         }
      });
   }])

   .service('Company', ['$resource', function($resource) {
      return $resource('api/company/:id', { id: '@id' }, {
         search: {
            url: 'api/company/search',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('Demo', ['$resource', function($resource) {
      return $resource('api/demo', {}, {
         access: {
            method: 'POST'
         }
      });
   }])

   .service('Product', ['$resource', function($resource) {
      return $resource('api/product/:id', { id: '@id' }, {
         search: {
            url: 'api/product/search',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('ClientProduct', ['$resource', function($resource) {
      return $resource('api/client/:clientId/product/:id', { id: '@id', clientId: '@clientId' }, {});
   }])

   .service('Role', ['$resource', function($resource) {
      return $resource('api/role/:name', { name: '@name', query: '@query' }, {
         search: {
            url: 'api/role/:name/users/:query',
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('SearchRole', ['$resource', function($resource) {
      return $resource('api/role/:type', { type: '@type' }, {
         byType: {
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('ActiveUsers', ['$resource', function($resource) {
      return $resource('api/role/:name/users/:status', { name: '@name', status: '@status' }, {
         update: {
            url: 'api/role/:name/users',
            method: 'PUT'
         }
      });
   }])

   .service('ActiveIdentities', ['$resource', function($resource) {
      return $resource('api/role/:name/identity', { name: '@name' }, {});
   }])

   .service('Account', ['$resource', function($resource) {
      return $resource('api/account', {}, {
         search: {
            url: 'api/account',
            method: 'POST',
            isArray: false
         }
      });
   }])

   .service('Template', ['$resource', function($resource) {
      return $resource('api/template', {}, {});
   }])

   .service('Document', ['$resource', function($resource) {
      return $resource('api/document/:id', { id: '@id' }, {});
   }])

   .service('RestProxy', ['$resource', function($resource) {
      return $resource('api/instance/:instanceId/rest', { instanceId: '@instanceId' }, {
         call: {
            method: 'POST'
         }
      });
   }])

   .service('GeneratePDF', ['$resource', function($resource) {
      return $resource('api/instance/:instanceId/pdf/fill', { instanceId: '@instanceId' }, {
         run: {
            method: 'POST'
         }
      });
   }])

   .service('Enumeration', ['$resource', function($resource) {
      return $resource('api/enumeration/:type', { type: '@type' }, {});
   }])

   .service('Model', ['$resource', function($resource) {
      return $resource('api/model/:name/:uuid', { name: '@name', uuid: '@uuid', query: '@query' }, {
         build: {
            url: 'api/model/build',
            method: 'POST'
         },
         search: {
            url: 'api/model/search/:query',
            method: 'POST',
            isArray: true
         },
         diagram: {
            url: 'api/model/:name/:uuid/diagram',
            method: 'POST'
         },
         update: {
            url: 'api/model/:name/:uuid',
            method: 'PUT'
         },
         schema: {
            url: 'api/model/:name/:uuid/schema',
            method: 'GET'
         },
         manual: {
            url: 'api/model/manual',
            method: 'GET',
            isArray: true
         }
      });
   }])

   .service('ModelHistory', ['$resource', function($resource) {
      return $resource('api/model/:name/history', { name: '@name' }, {});
   }])

   .service('Migration', ['$resource', function($resource) {
      return $resource('api/instance/migrate', { name: '@name' }, {
         run: {
            method: 'PUT'
         }
      });
   }])

   .service('InstanceGroup', ['$resource', function($resource) {
      return $resource('api/instance/group/:name/uuid', { name: '@name' }, {
         search: {
            method: 'POST',
            isArray: true
         }
      });
   }])

   .service('Operation', ['$resource', function($resource) {
      return $resource('api/humantask/instance', {}, {
         search: {
            method: 'POST'
         }
      });
   }])

   .service('Dashboard', ['$resource', function($resource) {
      return $resource('api/dashboard', {}, {
         data: {
            method: 'POST'
         }
      });
   }])

   .service('ModelDesign', ['$resource', function($resource) {
      return $resource('api/model/design/:name', {
         name: '@name',
         uuid: '@uuid',
         screen: '@screen',
         section: '@section'
      }, {
         addWidget: {
            url: 'api/model/design/:name/:uuid/screen/:screen/section/:section/widget',
            method: 'POST'
         },
         addSection: {
            url: 'api/model/design/:name/:uuid/screen/:screen/section',
            method: 'POST'
         },
         getSection: {
            url: 'api/model/design/:name/:uuid/screen/:screen/section/:section',
            method: 'GET'
         },
         addEdge: {
            url: 'api/model/design/:name/:uuid/edge',
            method: 'POST'
         },
         addSectionEdge: {
            url: 'api/model/design/:name/:uuid/screen/:screen/edge',
            method: 'POST'
         },
         deleteSectionEdge: {
            url: 'api/model/design/:name/:uuid/screen/:screen/edge/:edge',
            method: 'DELETE'
         },
         deleteEdge: {
            url: 'api/model/design/:name/:uuid/edge/:edge',
            method: 'DELETE'
         },
         editSection: {
            url: 'api/model/design/:name/:uuid/screen/:screen/section/:section',
            method: 'PUT'
         },
         deleteSection: {
            url: 'api/model/design/:name/:uuid/screen/:screen/section/:section',
            method: 'DELETE'
         },
         addTask: {
            url: 'api/model/design/:name/:uuid/screen',
            method: 'POST'
         },
         deleteTask: {
            url: 'api/model/design/:name/:uuid/screen/:screen',
            method: 'DELETE'
         }
      });
   }])

   .service('ModelData', ['$resource', function($resource) {
      return $resource('api/model/:name/:uuid/data', { name: '@name', uuid: '@uuid' }, {});
   }])

   .service('Task', ['$resource', function($resource) {
      return $resource('api/model/:name/:uuid/task/:taskName', {
         name: '@name',
         uuid: '@uuid',
         taskName: '@taskName'
      }, {});
   }])

   .service('Overview', ['$resource', function($resource) {
      return $resource('api/model/:name/:uuid/overview', { name: '@name', uuid: '@uuid' }, {});
   }])

   .service('Absent', ['$resource', function($resource) {
      return $resource('/api/absence/:user', { user: '@user' }, {});
   }])

   .service('Instance', ['$resource', function($resource) {
      return $resource('api/instance/:instanceId', { instanceId: '@instanceId' }, {
         redeploy: {
            url: 'api/instance/:instanceId/redeploy',
            method: 'PUT'
         },
         update: {
            url: '/api/instance/:instanceId/attributes',
            method: 'PUT'
         },
         humantasks: {
            url: 'api/instance/:instanceId/humantask',
            method: 'GET',
            isArray: true
         }
      });
   }])

   .service('Search', ['$resource', function($resource) {
      return $resource('api/instance/search', { query: '@query', size: '@size', page: '@page' }, {
         query: {
            url: 'api/instance/search/:query',
            method: 'POST'
         },
         mine: {
            url: 'api/instance/search/mine',
            method: 'POST'
         },
         solved: {
            url: 'api/instance/search/solved',
            method: 'POST'
         }
      });
   }])

   .factory('IntervalFactory', [function() {
      let methods = {};

      methods.build = function(shift, interval) {
         let result = {};
         result.startTime = moment().startOf(interval).add(shift, interval);
         result.endTime = moment().startOf(interval).add(1, interval).add(shift, interval);
         result.interval = (interval === 'month') ? 86400 : 3600;
         result.interval = result.interval * 1000;
         result.format = (interval === 'month') ? 'EEE d' : 'H';
         return result;
      };

      return methods;
   }])

   .factory('WidgetFactory', [function() {
      let methods = {};

      methods.generate = function(w, options) {
         let editorHtmlCode = '<' + w.ui + ' id="widget-ui-' + w.name;
         if (options && options.timestamp) {
            let dt = (new Date()).getTime();
            editorHtmlCode = editorHtmlCode + '-' + dt;
         }
         editorHtmlCode = editorHtmlCode + '" widget="w" instance="instance" screen="screen" section="section" humantask="humantask" ';
         if (options && options.widgetAction) {
            editorHtmlCode = editorHtmlCode + 'on-widget-action="widgetAction(widget, action, data)" ';
         }
         // attributes
         angular.forEach(w.binding, function(value, key) {
            if (value) {
               editorHtmlCode = editorHtmlCode + key + '="instance.attributes.' + value + '" ';
            }
         });
         if (!options.showInvisible) {
            editorHtmlCode = editorHtmlCode + ' ng-if="evaluateExpression(w.visible, instance, true)" ';
         }

         editorHtmlCode = editorHtmlCode + ' editable="';
         if (options && options.formLock) {
            editorHtmlCode = editorHtmlCode + '!locked() && ';
         }

         editorHtmlCode = editorHtmlCode + 'evaluateExpression(w.editable, instance, true)" ';
         editorHtmlCode = editorHtmlCode + ' required="evaluateExpression(w.required, instance, false)" ';
         editorHtmlCode = editorHtmlCode + '></' + w.ui + '>';
         return editorHtmlCode;
      };

      return methods;
   }])

   .factory('Work', ['InstanceExpression', function(InstanceExpression) {
      let methods = {};

      methods.findSection = function(snapshot, task) {
         let result = null;
         angular.forEach(task.sections, function(section) {
            if (snapshot.name === section.name) {
               result = section;
            }
         });
         return result;
      };

      methods.nominalWork = function(instance, task) {
         let result = 0;
         angular.forEach(instance.snapshots, function(snapshot) {
            let section = methods.findSection(snapshot, task);
            if (section && section.estimatedWork && section.estimatedWork.expression) {
               result += InstanceExpression.eval(section.estimatedWork.expression, snapshot); // jshint ignore:line
            }
         });
         return result;
      };

      return methods;
   }])

   .factory('Utils', ['$log', '$timeout', function($log, $timeout) {
      let methods = {};

      methods.lateWatch = function(context, delay, callback) {
         return function(newVal, oldVal) {
            if (context.timeout) {
               $timeout.cancel(context.timeout);
            }
            context.timeout = $timeout(callback, delay, true, newVal, oldVal);
         };
      };

      methods.getProperties = function(obj) {
         let result = [];
         for (let item in obj) {
            if (obj.hasOwnProperty(item) && item.indexOf('$') < 0) {
               result.push(item);
            }
         }
         return result;
      };

      methods.cleanUp = function(obj) {
         let result = {};
         for (let item in obj) {
            if (obj.hasOwnProperty(item) && item.indexOf('$') < 0) {
               if (obj[item]) {
                  result[item] = obj[item];
               }
            }
         }
         return result;
      };

      methods.isNumeric = function(n) {
         return !isNaN(parseFloat(n)) && isFinite(n);
      };

      methods.nullSafeEquals = function(obj1, obj2) {
         if (!obj1 && !obj2) {
            return true;
         } else if (obj1 && obj2) {
            return (obj1 === obj2);
         }
         return false;
      };

      methods.getObjectAttribute = (object, attrPath) => {
         return attrPath.split('.').reduce((acc, key) => {
            return acc?.[key] ?? undefined;
         }, object);
      };

      methods.getChangedAttributes = (changedObject, originalObject) => {
         let changedAttributes = {};

         if (!angular.equals(changedObject, originalObject)) {
            Object.keys(changedObject)
               .map((property) => {
                  if (!angular.equals(changedObject[property], originalObject[property])) {
                     return { [property]: changedObject[property] };
                  }
               })
               .filter(value => value !== undefined)
               .forEach(element => changedAttributes = { ...changedAttributes, ...element });
         }

         return changedAttributes;
      };

      return methods;
   }])

   .factory('TaskUrl', [function() {

      let methods = {};

      methods.get = function(id, mode) {
         let url = '/task/' + id;
         if (mode) {
            url = url + '/' + mode;
         } else {
            url = url + '/internal';
         }
         return url;
      };

      return methods;
   }])

   .factory('NewInstance', ['TaskUrl', 'Instance', '$rootScope', '$log', function(TaskUrl, Instance, $rootScope, $log) {

      let methods = {};

      methods.showTask = function(createInstanceResponse, callback) {
         $log.info('instance created: ' + createInstanceResponse.instance.instanceId);
         $log.info('auto-started humantasks:' + createInstanceResponse.humanTasks);

         let url = '/dashboard';
         if (createInstanceResponse.humanTasks && createInstanceResponse.humanTasks.length > 0) {
            let htref = createInstanceResponse.humanTasks[0];
            url = TaskUrl.get(htref.id);
         }
         callback(url);
      };

      methods.run = function(model, callback) {
         let i = new Instance();
         i.name = model.name;
         i.uuid = model.uuid;
         i.$save(function(createInstanceResponse) {
            methods.showTask(createInstanceResponse, callback);
         });
      };

      return methods;
   }])

   .factory('Edge', ['Utils', 'InstanceExpression', 'SectionEvent', function(Utils, InstanceExpression, SectionEvent) {

      let methods = {};
      let map = {};
      let last;

      methods.init = function(instance, screen, humantask) {
         map = {};
         last = undefined;
         if (instance.snapshots) {
            angular.forEach(instance.snapshots, function(snapshot) {
               map[snapshot.name] = { locked: true, visible: true };
               last = snapshot.name;
            });
         }
         methods.setVisible(instance, screen, humantask);
      };

      methods.evaluateNodes = function(node1, node2) {
         return Utils.nullSafeEquals(node1.type, node2.type) && Utils.nullSafeEquals(node1.name, node2.name);
      };

      methods.addSnapshot = function(instance, screen, section, humantask) {
         if (!instance.snapshots) {
            instance.snapshots = [];
         }
         let snapshot = { name: section.name, attributes: angular.copy(instance.attributes), timestamp: new Date() };
         instance.snapshots.push(snapshot);
         last = snapshot.name;
         map[last] = { locked: true, visible: true };

         let se = {
            humanTask: humantask,
            name: section.name,
            instance: { instanceId: instance.instanceId },
            eventType: 'CLOSE'
         };
         SectionEvent.push(se);

         methods.setVisible(instance, screen, humantask);
      };

      methods.restoreSnapshot = function(instance, screen, section, humantask) {
         if (!instance.snapshots) {
            instance.snapshots = [];
         }
         let i = instance.snapshots.length - 1;
         last = section.name;
         while (i >= 0 && instance.snapshots[i].name !== last) {
            i--;
         }
         if (i >= 0) {
            let oldAttrs = instance.snapshots[i].attributes;
            angular.copy(oldAttrs, instance.attributes);
            instance.snapshots.splice(i, instance.snapshots.length - i);
         }
         angular.forEach(map, function(item, key) {
            let found = false;
            for (let j = 0; j < instance.snapshots.length; j++) {
               if (key === instance.snapshots[j].name) {
                  found = true;
               }
            }
            if (!found) {
               map[key] = undefined;
            }
         });

         let se = {
            humanTask: humantask,
            name: section.name,
            instance: { instanceId: instance.instanceId },
            eventType: 'RESTORE'
         };
         SectionEvent.push(se);

         map[last] = { locked: false, visible: true };
      };

      methods.compute = function(currentNode, instance, screen) {
         let result = [];
         angular.forEach(screen.edges, function(edge) {
            if (methods.evaluateNodes(currentNode, edge.from)) {
               if (InstanceExpression.get(edge.condition, instance, true)) {
                  result.push(edge.to);
               }
            }
         });
         return result;
      };

      methods.isVisible = function(section) {
         let item = map[section.name];
         return (item && item.visible);
      };

      methods.isLocked = function(section) {
         let item = map[section.name];
         return (item && item.locked);
      };

      methods.getCurrentNode = function() {
         let currentNode;
         if (last) {
            currentNode = { type: 'SECTION', name: last };
         } else {
            currentNode = { type: 'START' };
         }
         return currentNode;
      };

      methods.setVisible = function(instance, screen, humantask) {
         let currentNode = methods.getCurrentNode();
         // push new sections
         let nodes = methods.compute(currentNode, instance, screen);
         angular.forEach(nodes, function(node) {
            if (node.type === 'SECTION') {
               map[node.name] = { locked: false, visible: true };

               let se = {
                  humanTask: humantask,
                  name: node.name,
                  instance: { instanceId: instance.instanceId },
                  eventType: 'OPEN'
               };
               SectionEvent.push(se);
            }
         });
      };

      return methods;
   }])

   .factory('ListUtils', [function() {

      let methods = {};

      methods.filter = function(list, filterData, filterFunction) {
         if (filterData) {
            let result = [];
            angular.forEach(list, function(item) {
               if (filterFunction(item)) {
                  result.push(item);
               }
            });
            return result;
         } else {
            return list;
         }
      };

      methods.fromMap = function(data) {
         let arr = [];
         for (let item in data) {
            if (data.hasOwnProperty(item) && item.indexOf('$') < 0) {
               if (data[item]) {
                  arr.push(data[item]);
               }
            }
         }
         return arr;
      };

      return methods;

   }])

   .factory('EnumerationCache', ['Enumeration', '$q', function(Enumeration, $q) {

      let enumerations = {};
      let promises = {};
      let methods = {};

      methods.get = function(type, callback) {
         let deffered = $q.defer();
         if (enumerations[type]) {
            deffered.resolve(enumerations[type]);
         } else {
            if (promises[type]) {
               promises[type].then(callback);
            } else {
               Enumeration.get({ type: type }, function(data) {
                  enumerations[type] = data;
                  delete promises[type];
                  deffered.resolve(enumerations[type]);
               });
            }
            promises[type] = deffered.promise;
         }
         return deffered.promise.then(callback);
      };

      methods.asArray = function(type, callback) {
         methods.get(type, function(map) {
            let list = [];
            angular.forEach(map, function(item, key) {
               if (key[0] !== '$') {
                  list.push(item);
               }
            });
            callback(list);
         });
      };

      return methods;
   }])

   .factory('InstanceExpression', ['$rootScope', function($rootScope) {
      let methods = {};

      methods.get = function(expression, instance, defaultResult) {
         if (!expression) {
            return defaultResult;
         }
         if (!expression.expression) {
            return defaultResult;
         }
         let exp = expression.expression;
         if (exp === 'true' || exp === true) {
            return true;
         }
         if (exp === 'false' || exp === false) {
            return false;
         }
         return methods.eval(exp, instance); // jshint ignore:line
      };

      methods.eval = function(exp, instance, item) { // jshint ignore:line
         let miniScope = $rootScope.$new();
         angular.forEach(instance.attributes, function(value, key) {
            miniScope[key] = value;
         });
         if (item) {
            miniScope.item = item;
         }
         miniScope._user = $rootScope.account;
         const result = miniScope.$eval(exp);
         return result;
      };

      return methods;
   }])

   .factory('EvalFactory', ['$interpolate', function($interpolate) {
      let methods = {};

      methods.run = function(template, obj) {
         if (!obj) {
            return '';
         }
         if (!template) {
            return '';
         }
         let exp = $interpolate(template);
         return exp(obj);
      };

      return methods;
   }])

   .factory('PersonFactory', [function() {
      let methods = {};

      methods.address = function(address) {
         if (!address) {
            return null;
         }
         const arr = [address.street, address.city, address.zipCode, address.country];
         let s = '';
         angular.forEach(arr, function(item) {
            if (item) {
               if (s !== '') {
                  s = s + ', ';
               }
               s = s + item;
            }
         });
         return s;
      };

      return methods;
   }])

   .factory('PropertiesLoader', [function() {
      let methods = {};

      methods.loadProperty = function(scope, widget, propertyName, defaultValue) {
         if (widget.properties && 'undefined' != typeof (widget.properties[propertyName])) {
            scope[propertyName] = widget.properties[propertyName];
         } else {
            scope[propertyName] = defaultValue;
         }
      };

      methods.load = function(scope, defaults) {
         let widget = scope.widget;
         angular.forEach(defaults, function(value, key) {
            methods.loadProperty(scope, widget, key, value);
         });
      };

      return methods;
   }])

   .service('ErrorToast', ['$mdToast', '$filter', function($mdToast, $filter) {
      return {
         show: function(message, callback) {
            let msg = $filter('translate')(message);
            let toast = $mdToast.simple()
               .textContent(msg)
               .action('BACK')
               .highlightAction(true)
               .highlightClass('md-accent')
               .position('bottom center')
               .hideDelay(0);

            $mdToast.show(toast).then(function(response) {
               if (response === 'ok') {
                  callback();
               }
            });
         }
      };
   }])

   .service('Session', [function() {
      this.create = function(data) {
         this.name = data.name;
         this.displayName = data.displayName;
         this.imageUrl = data.imageUrl;
         let userRoles = [];
         angular.forEach(data.roles, function(role) {
            userRoles.push(role.name);
         });
         this.userRoles = userRoles;
         this.email = data.email;
         this.profileUrl = data.profileUrl;
      };
      this.invalidate = function() {
         this.name = null;
         this.displayName = null;
         this.imageUrl = null;
         this.userRoles = null;
         this.profileUrl = null;
         this.email = null;
      };
      return this;
   }])

   .service('TokenStore', ['$cookies', function($cookies) {
      let methods = {};

      methods.setToken = function(newToken) {
         $cookies.put('hanko', newToken);
      };

      methods.getToken = function() {
         const token = $cookies.get('hanko') ?? $cookies.get('entra');
         return `Bearer ${token}`;
      };

      return methods;

   }])

   .service('AuthSharedService', ['$rootScope', '$http', '$resource', 'authService', 'Session', 'TokenStore', function($rootScope, $http, $resource, authService, Session, TokenStore) {

      let methods = {};

      methods.loadAccount = function() {
         $http.get('api/user').then(function(response) {
            authService.loginConfirmed(response.data);
         });
      };

      methods.getAccount = function() {
         return $rootScope.account;
      };

      methods.isAuthenticated = function() {
         return !!$rootScope.account;
      };

      methods.isAuthorized = function(authorizedRoles) {
         if (!angular.isArray(authorizedRoles)) {
            if (authorizedRoles === '*') {
               return true;
            }
            authorizedRoles = [authorizedRoles];
         }
         let isAuthorized = false;
         angular.forEach(authorizedRoles, function(role) {
            let authorized = (!!Session.name && Session.userRoles.indexOf(role) !== -1);
            if (authorized || role === '*') {
               isAuthorized = true;
            }
         });
         return isAuthorized;
      };

      return methods;
   }])

   .service('GoogleMapAddressBuilder', [function() {

      let methods = {};

      methods.build = function(address) {
         let result = '';
         if (address.street) {
            let number = address.primaryNumber;
            if (address.orientationNumber) {
               if (number) {
                  number = number + '/';
               }
               number = number + address.orientationNumber;
            }
            result = address.street + ' ' + number;
         } else {
            result = address.city + ' ' + address.orientationNumber;
         }
         if (address.zipCode) {
            result = result + ', ' + address.zipCode;
         }
         if (address.city) {
            result = result + ', ' + address.city;
         }
         return result;
      };

      return methods;
   }])

   .service('Users', ['$resource', function($resource) {
      return $resource('api/users/:id', { id: '@id' }, {});
   }])

   .service('HankoUsers', ['$resource', function($resource) {
      return $resource('hankoAdmin/users/:id', { id: '@id' }, {});
   }])

   .service('Roles', ['$resource', function($resource) {
      return $resource('api/roles/:id', { id: '@id' }, {});
   }])

   .service('Settings', ['$resource', function($resource) {
      return $resource('api/settings', {}, {});
   }])

   .service('SaveFile', function() {
      let methods = {};

      methods.saveTextAsFile = function(data, filename, defaultFileName, mimeType) {

         if (!data) {
            return;
         }

         if (!filename) filename = defaultFileName;

         const blob = new Blob([data], { type: mimeType });
         const a = document.createElement('a');
         document.body.appendChild(a);
         a.style = 'display: none';
         a.href = URL.createObjectURL(blob);
         a.download = filename;
         a.click();
         URL.revokeObjectURL(a.href);
         a.remove();
      };

      return methods;
   })

   .service('DownloadFile', ['$sce', '$http', 'SaveFile', '$mdDialog', function($sce, $http, SaveFile, $mdDialog) {
      let methods = {};

      methods.download = function(downloadUrl, fileName, defaultFileName, mimeType) {
         let url = $sce.trustAsResourceUrl(downloadUrl);
         $http.get(url, {responseType: 'arraybuffer'})
            .then(function(response) {
               SaveFile.saveTextAsFile(response.data, fileName, defaultFileName, mimeType);
            }).catch(function(e) {
            console.log('Error downloading file: ', e);
            $mdDialog.show(
               $mdDialog.alert()
                  .clickOutsideToClose(true)
                  .title('Chyba! Soubor neexistuje:')
                  .textContent(fileName)
                  .ok('OK')
            );
         }).finally(function() {
         });
      };

      return methods;
   }]);
