angular
   .module('org.saga.preventWheel', [])
//this directive is needed just to fix mouse wheel scroll issue caused by Clarity
   .directive('preventWheel', function () {
      return {
         restrict: 'A',
         link: function (scope, element) {
            element.on('wheel', function (event) {
               if (document.activeElement === element[0]) {
                  event.preventDefault();
               }
            });
         }
      };
   });
