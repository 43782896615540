import commandsTemplate from './commands.html';

angular.module('org.saga.commands', [
   'org.saga.service'
])

   .directive('screenCommands', ['$location', 'InstanceExpression',
      function($location, InstanceExpression) {
         return {
            restrict: 'E',
            controllerAs: 'ctrl',
            template: commandsTemplate,
            scope: {
               instance: '=',
               screen: '=',
               humantask: '=',
               onCommand: '&',
               primaryCommandDisabled: '&'
            },
            controller: ['$scope', function($scope) {
               $scope.icons = {
                  'CLOSE': 'close',
                  'COMPLETE': 'check',
                  'EXIT': 'delete',
                  'RELEASE': 'reply',
                  'SAVE': 'save',
                  'SUSPEND': 'pause',
                  //deprecated
                  'DONE': 'check_circle'
               };

               $scope.isOpen = false;
               $scope.primaryCommand = undefined;

               $scope.commands = [];

               if ($scope.screen.commands) {
                  angular.forEach($scope.screen.commands, function(command) {
                     if ($scope.humantask && command.type === 'COMPLETE') {
                        $scope.primaryCommand = command;
                     } else if (!$scope.humantask && !$scope.primaryCommand) {
                        $scope.primaryCommand = command;
                     } else {
                        $scope.commands.push(command);
                     }
                  });
               }

               $scope.commandEnabled = (command) => InstanceExpression.get(command.editable, $scope.instance, true);

               $scope.go = function(command) {
                  $scope.onCommand({ command: command });
               };

            }]
         };
      }]);
