import numberTemplate from './number.html';

angular
   .module('org.saga.widget.number', ['org.saga.service'])

   .directive('numberWidget', [
      'PropertiesLoader',
      function (PropertiesLoader) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: numberTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               required: '=',
               value: '=?'
            },
            link: function (scope, element, attrs, form) {
               scope.form = form;
               element.on('wheel', function (event) {
                     // event.preventDefault();
                  console.log(scope.value);
               });
            },
            controller: [
               '$scope',
               'DefaultProperties',
               '$translate',
               function ($scope, DefaultProperties, $translate) {
                  PropertiesLoader.load($scope, DefaultProperties.number);

                  $scope.autoHideWarnings = !($scope.showValidateWarnings === 'force');

                  $scope.getErrorMessage = function (message, value) {
                     return $translate.instant($scope.errors[message], { value: value });
                  };
               }
            ]
         };
      }
   ]);
