const { startOfDay, formatISO, isSameDay, isDate, set } = require('date-fns');
import timesheetTemplate from './timesheet.html';

angular
   .module('org.saga.widget.timesheet', ['org.saga.service', 'ngMaterialDatePicker', 'isolateForm'])
   .filter('getLabel', [
      '$parse',
      function ($parse) {
         return function (item, labelExpression) {
            if (!labelExpression) {
               return item;
            }
            const parsedExpression = $parse(labelExpression);
            return parsedExpression({ item: item });
         };
      }
   ])
   .filter('dynamicExpFilter', [
      '$parse',
      function ($parse) {
         return function (items, filterExpression) {
            if (!filterExpression) {
               return items;
            }
            const parsedExpression = $parse(filterExpression);
            return items.filter((item) => {
               return parsedExpression({ item: item });
            });
         };
      }
   ])
   .filter('duration', function () {
      return function (durationInMs) {
         const totalSeconds = Math.floor(durationInMs / 1000);
         const hours = Math.floor(totalSeconds / 3600);
         const minutes = Math.floor((totalSeconds % 3600) / 60);
         const twoPlaceMinutes = minutes === 0 ? '00' : minutes;

         return `${hours}:${twoPlaceMinutes}`;
      };
   })
   .directive('timesheetWidget', [
      'PropertiesLoader',
      'DefaultProperties',
      '$translate',
      function (PropertiesLoader, DefaultProperties, $translate, InstanceExpression) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: timesheetTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               required: '=',
               output: '=?'
            },
            link: function (scope, element, attrs, form) {
               scope.form = form;
            },
            controller: [
               '$scope',
               '$parse',
               'InstanceExpression',
               function ($scope, $parse, InstanceExpression) {
                  PropertiesLoader.load($scope, DefaultProperties.timesheet);

                  $scope.output = $scope.output ?? {
                     totalDuration: 0,
                     totalManDays: 0,
                     records: []
                  };
                  $scope.newRecord = {};

                  function getApprovedRecords() {
                     if ($scope.output.records && $scope.output.records.length > 0) {
                        return angular.copy(
                           $scope.output.records.filter((record) => record.approved)
                        );
                     } else {
                        return [];
                     }
                  }

                  $scope.approvedRecords = getApprovedRecords();

                  $scope.toggle = function (record) {
                     record.approved = !record.approved;
                     if (record.approved) {
                        record.approverComment = '';
                        $scope.approvedRecords.push(record);
                     } else {
                        $scope.approvedRecords.splice($scope.approvedRecords.indexOf(record), 1);
                     }
                  };

                  function getValueFromAttribute(attribute) {
                     if (typeof attribute === 'string') {
                        return InstanceExpression.eval(attribute, $scope.instance);
                     } else {
                        return attribute;
                     }
                  }

                  $scope.agreements = getValueFromAttribute($scope.agreements);
                  $scope.projects = getValueFromAttribute($scope.projects);
                  $scope.activityTypes = getValueFromAttribute($scope.activityTypes);

                  function setDefaultAgreement(agreements) {
                     if (agreements.length === 1) {
                        $scope.newRecord.agreement = agreements[0];
                        setDefaultPosition(agreements[0].positions);
                        setLocalProjectsByProjectId($scope.projects, agreements[0].projectId);
                     }
                  }

                  function setLocalProjectsByProjectId(projects, projectId) {
                     const filteredProjects = projects.filter((project) =>
                        projectId.includes(project.id)
                     );
                     $scope.localProjects = filteredProjects;
                     setDefaultProject(filteredProjects);
                  }

                  function setDefaultPosition(positions) {
                     if (positions.length === 1) {
                        $scope.newRecord.position = positions[0];
                        setLocalActivityTypesByPosition($scope.activityTypes, positions[0].positionMPSV);
                     }
                  }

                  function setLocalActivityTypesByPosition(activityTypes, position) {
                     const filteredActivityTypes = activityTypes.find(
                        (activityType) => activityType.name === position
                     );
                     $scope.activityTypesLocal = filteredActivityTypes
                        ? filteredActivityTypes.activities
                        : [];

                     setDefaultActivityType($scope.activityTypesLocal);
                  }

                  function setDefaultActivityType(activities) {
                     if (activities.length === 1) {
                        $scope.newRecord.activityType = activities[0];
                     }
                  }

                  function setDefaultProject(projects) {
                     if (projects.length === 1) {
                        $scope.newRecord.project = projects[0];
                     }
                  }

                  function setTotalDuration() {
                     $scope.output.totalDuration = $scope.output.records.reduce(
                        (acc, timesheet) => acc + timesheet.duration,
                        0
                     );
                     $scope.output.totalManDays = $scope.output.records.reduce(
                        (acc, timesheet) => acc + timesheet.manDays,
                        0
                     );
                  }

                  setDefaultAgreement($scope.agreements);

                  $scope.deleteRecord = function (recordKey) {
                     $scope.output.records.splice(recordKey, 1);
                     setTotalDuration();
                  };

                  $scope.editRecord = function (record, recordKey) {
                     $scope.duplicateRecord(record);
                     $scope.deleteRecord(recordKey);
                  };

                  $scope.duplicateRecord = function (recordToDuplicate) {
                     $scope.isEditing = true;
                     const record = angular.copy(recordToDuplicate);
                     const project = $scope.projects.find(
                        (project) => project.id === record.project.id
                     );
                     const agreement = $scope.agreements.find(
                        (agreement) => agreement.agreementNumber === record.agreementNumber
                     );
                     const position = agreement.positions.find(
                        (position) => position.positionMPSV === record.positionMPSV
                     );

                     $scope.newRecord = {
                        ...record,
                        agreement: agreement,
                        position: position,
                        project: project,
                        date: new Date(record.date),
                        start: new Date(record.start),
                        end: new Date(record.end)
                     };
                  };

                  $scope.formatAndSave = function (record) {
                     const data = {
                        ...record,
                        start: formatISO(record.start),
                        date: formatISO(record.date, { representation: 'date' }),
                        end: formatISO(record.end),
                        agreementNumber: record.agreement.agreementNumber,
                        agreement: record.agreement.agreement,
                        positionMPSV: record.position.positionMPSV,
                        price: record.position.price,
                        md: record.position.md,
                        project: {
                           id: record.project.id,
                           displayName: record.project.displayName,
                           project: record.project.project
                        },
                        activityType: record.activityType,
                        description: record.description,
                        links: record.links,
                        duration: record.end - record.start,
                        manDays: (record.end - record.start) / 1000 / 60 / 60 / 8
                     };
                     $scope.output.records.push(data);
                     $scope.output.records.sort((a, b) => {
                        const dateA = new Date(a.start);
                        const dateB = new Date(b.start);
                        return dateA - dateB;
                     });
                     setTotalDuration();
                     $scope.newRecord = {};
                     setDefaultAgreement($scope.agreements);
                  };

                  const checkRecordIntervalOverlap = (newRecord) =>
                     $scope.output.records.some((rec) => {
                        const recStartTime = new Date(rec.start);
                        const recEndTime = new Date(rec.end);
                        return (
                           (newRecord.start <= recStartTime && newRecord.end > recStartTime) ||
                           (newRecord.start < recEndTime && newRecord.end >= recEndTime) ||
                           (newRecord.start >= recStartTime && newRecord.end <= recEndTime)
                        );
                     });

                  $scope.saveRecord = function (newRecord) {
                     $scope.error = false;
                     const recordIntervalOverlap = checkRecordIntervalOverlap(newRecord);

                     if (recordIntervalOverlap) {
                        $scope.error = true;
                     } else {
                        $scope.formatAndSave(newRecord);
                     }
                  };

                  $scope.$watch('newRecord.date', (val) => {
                     if (val) {
                        $scope.startOfSelectedDay = startOfDay($scope.newRecord.date);
                        $scope.endOfSelectedDay = set($scope.newRecord.date, {
                           hours: 23,
                           minutes: 45
                        });

                        if ($scope.newRecord.start) {
                           //in case of editing, we need to update the date of the start and end time
                           $scope.newRecord.start = new Date(
                              val.getFullYear(),
                              val.getMonth(),
                              val.getDate(),
                              $scope.newRecord.start.getHours(),
                              $scope.newRecord.start.getMinutes()
                           );
                        }
                     }
                  });

                  $scope.$watch('newRecord.start', (val) => {
                     $scope.error = false;
                     if (val) {
                        //in case of editing, we need to update the date of the end time
                        if ($scope.newRecord.end && !isSameDay(val, $scope.newRecord.end)) {
                           $scope.newRecord.end = new Date(
                              val.getFullYear(),
                              val.getMonth(),
                              val.getDate(),
                              $scope.newRecord.end.getHours(),
                              $scope.newRecord.end.getMinutes()
                           );
                        }
                     }
                  });

                  function getDateObject(val) {
                     if (typeof val === 'string' && val !== '') {
                        return new Date(val);
                     } else if (isDate(val)) {
                        return val;
                     }
                  }

                  $scope.$watch('instance.attributes.' + $scope.minDate, (val) => {
                     $scope.minDateObject = getDateObject(val);
                  });

                  $scope.$watch('instance.attributes.' + $scope.maxDate, (val) => {
                     $scope.maxDateObject = getDateObject(val);
                  });

                  function isObjectEmpty(obj) {
                     return Object.keys(obj).length === 0;
                  }

                  $scope.$watch('newRecord.agreement', (val) => {
                     if (val && !isObjectEmpty(val)) {
                        if (!$scope.isEditing) {
                           $scope.newRecord.position = undefined;
                           $scope.newRecord.project = undefined;
                           $scope.newRecord.activityType = undefined;
                        }
                        $scope.positions = val.positions;
                        setDefaultPosition(val.positions);
                        setLocalProjectsByProjectId($scope.projects, val.projectId);
                     }
                  });

                  $scope.$watch('newRecord.position', (val) => {
                     if (val && !isObjectEmpty(val)) {
                        if (!$scope.isEditing) {
                           $scope.newRecord.activityType = undefined;
                        }
                        $scope.isEditing = false;
                        setLocalActivityTypesByPosition($scope.activityTypes, val.positionMPSV);
                     }
                  });
               }
            ]
         };
      }
   ]);
